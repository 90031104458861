import React from 'react';
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	Paper,
	Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const TeamRoles = ({ teamRoleData }) => {
	if (!teamRoleData) {
		return <Typography>Tiimiroolidataa ei ole saatavilla.</Typography>;
	}

	const roles = [
		{
			title: 'Toimintaorientoituneet roolit',
			descriptions: [
				{
					name: 'Haastaja',
					key: 'challenger',
					description:
						'Haastajat ovat ihmisiä, jotka haastavat tiimiä kehittymään. He ovat dynaamisia ja yleensä ulospäin suuntautuneita ihmisiä, jotka nauttivat ajatusten stimuloinnista, normien kyseenalaistamisesta ja he usein löytävät parhaat keinot ongelmien ratkaisemiseen. Haastaja ravistelee tiimiä varmistaakseen, että kaikki erilaiset mahdollisuudet otetaan huomioon, mutta myös sen, että ideat ja konseptit voivat toimia käytännössä.',
				},
				{
					name: 'Toteuttaja',
					key: 'implementer',
					description:
						'Toteuttajat saavat asiat tehdyiksi. He muuttavat tiimin ideat ja konseptit käytännön toiminnaksi ja suunnitelmiksi. Toteuttajat ovat yleensä konservatiivisia, kurinalaisia ihmisiä, jotka työskentelevät systemaattisesti ja tehokkaasti sekä ovat yleensä hyvin järjestelmällisiä.',
				},
				{
					name: 'Viimeistelijä',
					key: 'finisher',
					description:
						'Viimeistelijät huolehtivat projektien valmistumisesta perusteellisesti. He varmistavat, ettei työssä ole virheitä tai puutteita sekä kiinnittävät huomiota pienimpiin yksityiskohtiin. Viimeistelijät ovat hyvin tietoisia ryhmän deadlinesta sekä vievät tiimiä eteenpäin varmistaakseen, että työ valmistuu ajallaan. Kun ongelmia ilmenee, nämä henkilöt usein reagoivat nopeasti, jotta suunnitelmat etenevät aikataulussa ja tavoitteet voidaan saavuttaa. Viimeistelijöitä kuvataan perfektionisteiksi, jotka ovat järjestelmällisiä, tunnollisia mutta joskus myös helposti ahdistuneita tiimityössä. Viimeistelijä saattaakin huolestua tarpeettomasti ja delegointi voi olla hänelle hankalaa.',
				},
			],
		},
		{
			title: 'Ihmiskeskeiset roolit',
			descriptions: [
				{
					name: 'Koordinaattori',
					key: 'coordinator',
					description:
						'Koordinaattorit ovat luontaisia tiimijohtajia, ja heitä myös monesti ehdotetaankin puheenjohtajaksi tai hallituksen jäseneksi. He ohjaavat tiimiä havaitsemiaan tavoitteita kohti. Koordinaattorit ovat usein erinomaisia kuuntelijoita sekä pystyvät luontaisesti tunnistamaan jokaisen tiimiläisen arvon. He ovat rauhallisia ja monesti hyväntuulisia persoonia sekä delegoivat tehtäviä erittäin tehokkaasti. Koordinaattorien mahdollisia heikkouksia ovat, että he saattavat siirtää itseltään pois liiankin paljon henkilökohtaista vastuuta ja jättää itselleen vain vähän tekemistä.',
				},
				{
					name: 'Tiimipelaaja',
					key: 'teamPlayer',
					description:
						'Tiimipelaajat ovat henkilöitä, jotka tarjoavat tukea ja varmistavat, että tiimissä työskennellään tehokkaasti yhdessä. Tiimipelaajat ovat usein tiimille luontaisia neuvottelijoita ja he ovat joustavia, diplomaattisia sekä tarkkaavaisia. Nämä ovat yleensä suosittuja henkilöitä, jotka ovat luonnostaan hyvin kyvykkäitä, mutta asettavat kuitenkin etusijalle sen, että ihmiset tulevat keskenään hyvin toimeen ja tiimi on yhtenäinen. Tiimipelaajien heikkouksia voivat olla taipumus olla jahkailevia sekä olla ottamatta kantaa keskustelujen tai päätöksenteon aikana. He eivät myöskään mielellään paljasta epäsuosittuja mielipiteitä.',
				},
				{
					name: 'Tutkija',
					key: 'researcher',
					description:
						'Tutkijat ovat innovatiivisia ja uteliaita. He tunnistavat ja tutkivat mahdollisia vaihtoehtoja ja sitten myyvät ne muille, sekä tiimin sisällä että ulkopuolella. Resurssitutkijat luovat mielellään kontakteja sekä neuvottelevat resursseista tiimin puolesta. Resurssitutkijat ovat innostuneita tiimin jäseniä, jotka tunnistavat tiimin ulkopuoliset sidosryhmät ja työskentelevät heidän kanssaan auttaakseen tiimiä saavuttamaan tavoitteensa. He ovat meneviä sekä usein ekstroverttejä, ja siksi muut ovat monesti vastaanottavaisia heille ja heidän ideoilleen. Tutkijoiden heikkoutena on, että he saattavat menettää innostuksensa nopeasti ja saattavat olla ylioptimistisia. Resurssitutkijat saattavat myös helposti unohtaa tarkistaa työtehtäviensä perään.',
				},
			],
		},
		{
			title: 'Ajatteluun liittyvät roolit',
			descriptions: [
				{
					name: 'Visionääri',
					key: 'visionary',
					description:
						'Visionääri on luova innovaattori, joka on hyvä tuottamaan uusia ideoita ja lähestymistapoja. Heillä on hyvä mielikuvitus sekä innovatiivinen tapa ajatella. Visionäärit nauttivat kehuista erityisesti ja heidän on sitä vastoin erityisen hankalaa käsitellä kritiikkiä. Heihin saatetaan toisinaan viitata myös introvertteina, jotka haluavat työskennellä erillään tiimistä. Koska heidän ideansa ovat niin uudenlaisia, ne saattavat toisinaan olla myös epäkäytännöllisiä. Visionäärien heikkoudet voivat olla kommunikoinnissa ja heillä voi olla taipumus jättää huomiotta tiedossa olevia muuttujia tai rajoituksia. Visionäärit saattavat olla poissaolevan tai unohtelevan oloisia.',
				},
				{
					name: 'Analyytikko',
					key: 'analyst',
					description:
						'Analyytikot ovat parhaimmillaan, kun he analysoivat ja arvioivat muiden ihmisten (usein Visionäärien) keksimiä ideoita. Nämä henkilöt ovat älykkäitä ja objektiivisia sekä punnitsevat huolellisesti kaikkien vaihtoehtojen edut ja haitat ennen kuin tekevät päätöksen. Tämä rooli vaatii tarkkuutta sekä huomiota yksityiskohtiin. Analyytikot ovat kriittisiä ajattelijoita ja erittäin strategisia lähestymistavassaan. Heidät saatetaan kuitenkin kokea monesti puolueettomiksi tai tunteettomiksi, eivätkä he ole hääppöisiä motivoimaan muita. Analyytikot ovat mieluummin reaktiivisia kuin proaktiivisia.',
				},
				{
					name: 'Asiantuntija',
					key: 'specialist',
					description:
						'Asiantuntijat ovat niitä henkilöitä, joilla on erityistä työn suorittamiseen vaadittavaa tietoa. He ovat ylpeitä taidoistaan ja kyvyistään sekä tekevät töitä säilyttääkseen ammatillisen asemansa. Heidän tehtävänsä tiimissä on olla alan ammattilainen ja he sitoutuvat täysin omaan osaamisalueeseensa. Tämä saattaa rajoittaa heidän panostaan tiimille ja johtaa pienten teknisten yksityiskohtien viilaamiseen isomman kuvan kustannuksella.',
				},
			],
		},
	];

	// Lasketaan kolmen vahvimman tiimiroolin tiedot
	const sortedRoles = Object.entries(teamRoleData).sort(
		(a, b) => b[1] - a[1],
	);
	const topThreeRoles = sortedRoles.slice(0, 3).map((role) => ({
		key: role[0],
		score: role[1],
	}));

	const topRolesDescriptions = topThreeRoles
		.map((role) => {
			for (const group of roles) {
				const foundRole = group.descriptions.find(
					(desc) => desc.key === role.key,
				);
				if (foundRole) {
					return { ...foundRole, score: role.score };
				}
			}
			return null;
		})
		.filter(Boolean);

	return (
		<div>
			<Typography variant="subtitle1">Vahvimmat tiimiroolisi</Typography>
			{topRolesDescriptions.map((role, index) => (
				<Box key={index} sx={{ marginBottom: 2 }}>
					<Accordion defaultExpanded={false}>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography variant="subtitle1">
								<b>{role.name}</b> ({role.score}p)
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography paragraph>
								{role.description}
							</Typography>
						</AccordionDetails>
					</Accordion>
				</Box>
			))}
		</div>
	);
};

export default TeamRoles;
