import React, { useEffect, useRef, useState } from 'react';
import {
	Card,
	CardContent,
	Typography,
	Box,
	Chip,
	Grid,
	Avatar,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';

const TalentCard = ({ talent, vs_score, fts_score }) => {
	const [showHourlyRate, setShowHourlyRate] = useState(true);
	const navigate = useNavigate();
	const tickerRef = useRef(null);
	const listRef = useRef(null);
	const [scrollDuration, setScrollDuration] = useState(0);
	const [shouldScroll, setShouldScroll] = useState(false);
	const [isSingleSkill, setIsSingleSkill] = useState(false);

	const handleCardClick = () => {
		navigate(`${talent.id}`);
	};

	const totalScore = vs_score + fts_score;

	const chartData = {
		labels: ['Vector Search', 'Full Text Search', 'Remaining'],
		datasets: [
			{
				data: [vs_score, fts_score, 1 - totalScore],
				backgroundColor: ['#3f51b5', '#ff4081', '#e0e0e0'],
				borderWidth: 0,
			},
		],
	};

	const chartOptions = {
		cutout: '70%',
		plugins: {
			legend: {
				display: false,
			},
		},
		maintainAspectRatio: false,
	};

	const getRankColor = (rank) => {
		switch (rank) {
			case 1:
				return '#ffb300';
			case 2:
				return '#2196f3';
			case 3:
				return '#4caf50';
			default:
				return '#e0e0e0';
		}
	};

	const getRankLabel = (rank) => {
		switch (rank) {
			case 1:
				return 'Seniori';
			case 2:
				return 'Asiantuntija';
			case 3:
				return 'Opiskelija';
			default:
				return 'Tuntematon';
		}
	};

	useEffect(() => {
		const tickerElement = tickerRef.current;
		const listElement = listRef.current;

		if (tickerElement && listElement) {
			const tickerWidth = tickerElement.clientWidth;
			const listWidth = listElement.scrollWidth;

			if (talent.skills.length === 1) {
				setIsSingleSkill(true);
				setShouldScroll(false);
			} else if (listWidth > tickerWidth) {
				const scrollSpeed = 35; // Vakionopeus pikseleinä per sekunti
				const duration = listWidth / scrollSpeed; // Lasketaan animaation kesto

				setScrollDuration(duration);
				setShouldScroll(true);
				setIsSingleSkill(false);
			} else {
				setShouldScroll(false);
				setIsSingleSkill(false);
			}
		}
	}, [talent.skills]);

	const talentRate = talent?.hourlyRate / 2; // Laskutushinta yritykselle

	return (
		<Grid item xs={12} sm={6} md={4}>
			<Card
				sx={{
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
					cursor: 'pointer',
				}}
				onClick={handleCardClick}
			>
				<CardContent>
					<Box>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
								mb: 2,
							}}
						>
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<Avatar
									alt={talent?.user?.name}
									src={talent?.user?.avatarUrl}
									sx={{
										width: 56,
										height: 56,
										marginRight: 2,
									}}
								/>
								<Typography variant="h6">
									{talent?.user?.name.split(' ')[0]}
								</Typography>
							</Box>
							<Chip
								label={getRankLabel(talent?.talentPoolRank)}
								sx={{
									backgroundColor: getRankColor(
										talent?.talentPoolRank,
									),
									color: '#fff',
									height: 24,
									fontSize: '0.75rem',
									fontWeight: 'bold',
								}}
							/>
						</Box>
						{/* Kategoriat nimen alla */}
						<Box
							sx={{
								display: 'flex',
								flexWrap: 'wrap',
								gap: 0.5,
								mt: 1,
							}}
						>
							{talent?.category?.map((category, index) => (
								<Chip
									key={index}
									label={category}
									size="small"
									sx={{
										backgroundColor: 'rgba(0, 0, 0, 0.1)',
										color: 'black',
										fontSize: '0.75rem',
										fontWeight: 'bold',
									}}
								/>
							))}
						</Box>
						<Typography
							variant="body2"
							color="text.secondary"
							sx={{ mt: 1 }}
						>
							{talent?.major} - {talent?.university}
						</Typography>
					</Box>
					<Typography
						variant="body2"
						color="text.secondary"
						sx={{ mt: 1 }}
					>
						{talent?.description?.slice(0, 100)}
						{talent?.description?.length > 100 && '...'}
					</Typography>
					<Typography
						variant="subtitle2"
						color="text.primary"
						sx={{ mt: 2 }}
					>
						{showHourlyRate
							? `${talentRate} €/h `
							: `${talent?.hourlyRate} €/h `}
					</Typography>
				</CardContent>
				<SkillsTicker ref={tickerRef}>
					<SkillsList
						ref={listRef}
						shouldScroll={shouldScroll}
						duration={scrollDuration}
						isSingleSkill={isSingleSkill}
					>
						{talent?.skills.map((skill, index) => (
							<SkillItem key={index}>{skill.skill}</SkillItem>
						))}
						{shouldScroll &&
							talent?.skills.map((skill, index) => (
								<SkillItem key={index + talent?.skills.length}>
									{skill.skill}
								</SkillItem>
							))}
					</SkillsList>
				</SkillsTicker>
				<Box
					sx={{
						position: 'absolute',
						bottom: 50,
						right: 10,
						width: 50,
						height: 50,
					}}
				>
					<Doughnut data={chartData} options={chartOptions} />
				</Box>
			</Card>
		</Grid>
	);
};

const SkillsTicker = styled('div')(({ theme }) => ({
	position: 'relative',
	bottom: 10,
	width: '100%',
	backgroundColor: theme.palette.grey[900],
	color: theme.palette.common.white,
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	padding: '4px 0',
	textAlign: 'center',
}));

const SkillsList = styled('div')(
	({ shouldScroll, duration, isSingleSkill }) => ({
		display: 'inline-flex',
		alignItems: 'center',
		justifyContent: isSingleSkill ? 'center' : 'initial',
		animation: shouldScroll
			? `scroll ${duration}s linear infinite`
			: 'none',
		animationPlayState: 'running',
		'@keyframes scroll': {
			'0%': {
				transform: 'translateX(0)',
			},
			'100%': {
				transform: 'translateX(-50%)',
			},
		},
	}),
);

const SkillItem = styled('span')({
	margin: '0 30px',
	fontSize: '0.875rem',
	fontWeight: 500,
});

export default TalentCard;
