import Layout from './components/Layout';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUser } from './features/User/userSlice';
import { setToken as setAxiosToken } from './services/axios';
import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';

import CompaniesPage from 'features/Companies/components/CompaniesPage';
import CompanyPage from 'features/Companies/components/CompanyPage';
import ErrorPage from 'pages/ErrorPage';
import Main from 'pages/MainPage';
import ManagePage from 'pages/ManagePage';
import RecruitListPage from 'features/Recruits/pages/RecruitListPage';
import RecruitPage from 'features/Recruit/components/RecruitPage';
import Talent from 'features/Talent/Talent';
import RecruitsWidget from 'features/Recruits/components/RecruitsWidget';
import Talents from 'features/Talents/components/Talents';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { checkAuthLoader } from 'util/auth';
import LandingPageV2 from 'features/LandingPagev2/LandingPageV2';
import TalentSearch from 'features/TalentSearch/components/TalentSearch';
import NotificationDialog from 'features/Notification/components/NotificationDialog';
import AuthForm from 'features/Auth/components/AuthForm';
import AcademyPage from 'pages/AcademyPage';

function App() {
	const dispatch = useDispatch();

	useEffect(() => {
		const loggedUserJSON = window.localStorage.getItem('loggedUser');
		if (loggedUserJSON) {
			const loggedUser = JSON.parse(loggedUserJSON);
			dispatch(setUser(loggedUser.user));
			setAxiosToken(loggedUser.tokens.access.token);
		}
	}, [dispatch]);

	const queryClient = new QueryClient();

	const modernTheme = createTheme({
		shape: {
			borderRadius: 12, // Yleinen borderRadius kaikille komponenteille
		},
		palette: {
			mode: 'light',
			primary: {
				main: '#1c1e29', // Yrityksen tummansininen
				contrastText: '#ffffff', // Valkoinen kontrastiväri teksteille
			},
			secondary: {
				main: '#ffea94', // Yrityksen keltainen
			},
			background: {
				default: '#f0f0f5', // Pehmeän vaalea taustaväri
				paper: '#ffffff', // Valkoinen Paper-komponenttien taustaväri
			},
			text: {
				primary: '#1c1e29', // Tumma ensisijainen tekstiväri
				secondary: '#6e6e73', // Harmaa toissijainen tekstiväri
			},
		},
		typography: {
			fontFamily: 'Inter, Arial, sans-serif', // Moderni fonttiperhe
			h1: {
				fontFamily: 'Inter, Arial, sans-serif',
				fontWeight: 700,
			},
			h2: {
				fontFamily: 'Inter, Arial, sans-serif',
				fontWeight: 700,
			},
			h3: {
				fontFamily: 'Inter, Arial, sans-serif',
				fontWeight: 700,
			},
			h4: {
				fontFamily: 'Inter, Arial, sans-serif',
				fontWeight: 700,
			},
			h5: {
				fontFamily: 'Inter, Arial, sans-serif',
				fontWeight: 700,
			},
			h6: {
				fontFamily: 'Inter, Arial, sans-serif',
				fontWeight: 700,
			},
			button: {
				textTransform: 'none', // Poista tekstin muuntaminen napin teksteistä
				fontWeight: 600, // Lihavoi napin tekstit
			},
			body1: {
				fontFamily: 'Inter, Arial, sans-serif',
			},
			body2: {
				fontFamily: 'Inter, Arial, sans-serif',
			},
		},
		components: {
			MuiButton: {
				styleOverrides: {
					root: {
						borderRadius: 12, // Pyöristetyt kulmat nappeihin
						padding: '10px 20px', // Lisää napin pehmusteita
						boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Kevyt varjo
					},
					containedPrimary: {
						backgroundColor: '#1c1e29',
						'&:hover': {
							backgroundColor: '#17191e',
						},
					},
				},
			},
			MuiPaper: {
				styleOverrides: {
					root: {
						borderRadius: 12, // Pyöristetyt kulmat Paper-komponentteihin
						boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)', // Kevyt varjo
					},
				},
			},
			MuiAppBar: {
				styleOverrides: {
					root: {
						backgroundColor: '#ffffff', // Valkoinen taustaväri
						color: '#1c1e29', // Tumma tekstiväri
						boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)', // Kevyt varjo
						margin: '20px', // Marginaali ympärillä
						borderRadius: '12px', // Pyöristetyt kulmat
					},
				},
			},
			MuiTextField: {
				styleOverrides: {
					root: {
						borderRadius: 8, // Pyöristetyt kulmat tekstikenttiin
					},
				},
			},
			MuiDrawer: {
				styleOverrides: {
					paper: {
						backgroundColor: '#f5f5f5', // Vaalea taustaväri
						width: 240, // Leveämpi Drawer
						boxShadow: '2px 0px 5px rgba(0, 0, 0, 0.1)', // Kevyt varjo
						margin: '20px', // Marginaali ympärillä
						borderRadius: '12px', // Pyöristetyt kulmat
					},
				},
			},
			MuiToolbar: {
				styleOverrides: {
					root: {
						minHeight: 64, // Korkeampi Toolbar
					},
				},
			},
		},
	});

	const darkTheme = createTheme({
		shape: {
			borderRadius: 12,
		},
		palette: {
			mode: 'dark',
			primary: {
				main: '#1c1e29',
				contrastText: '#ffffff',
			},
			secondary: {
				main: '#ffea94',
			},
			background: {
				default: '#121212',
				paper: '#1e1e2e', // Slightly lighter to distinguish paper from background
			},
			text: {
				primary: '#ffffff',
				secondary: '#b0b0b0', // Brighter grey for better contrast
			},
		},
		typography: {
			fontFamily: 'Inter, Arial, sans-serif',
			h1: { fontFamily: 'Inter, Arial, sans-serif', fontWeight: 700 },
			h2: { fontFamily: 'Inter, Arial, sans-serif', fontWeight: 700 },
			h3: { fontFamily: 'Inter, Arial, sans-serif', fontWeight: 700 },
			h4: { fontFamily: 'Inter, Arial, sans-serif', fontWeight: 700 },
			h5: { fontFamily: 'Inter, Arial, sans-serif', fontWeight: 700 },
			h6: { fontFamily: 'Inter, Arial, sans-serif', fontWeight: 700 },
			button: { textTransform: 'none', fontWeight: 600 },
			body1: { fontFamily: 'Inter, Arial, sans-serif' },
			body2: { fontFamily: 'Inter, Arial, sans-serif' },
		},
		components: {
			MuiButton: {
				styleOverrides: {
					root: {
						borderRadius: 12,
						padding: '10px 20px',
						boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
					},
					containedPrimary: {
						backgroundColor: '#1c1e29',
						'&:hover': { backgroundColor: '#17191e' },
					},
				},
			},
			MuiPaper: {
				styleOverrides: {
					root: {
						borderRadius: 12,
						boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)', // Darker shadow for better contrast
					},
				},
			},
			MuiAppBar: {
				styleOverrides: {
					root: {
						backgroundColor: '#1c1e29',
						color: '#ffffff',
						boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.5)', // Darker shadow for better contrast
						margin: '20px',
						borderRadius: '12px',
					},
				},
			},
			MuiTextField: {
				styleOverrides: {
					root: { borderRadius: 8 },
				},
			},
			MuiDrawer: {
				styleOverrides: {
					paper: {
						backgroundColor: '#1c1e29',
						width: 240,
						boxShadow: '2px 0px 5px rgba(0, 0, 0, 0.3)', // Darker shadow for better contrast
						margin: '20px',
						borderRadius: '12px',
					},
				},
			},
			MuiToolbar: {
				styleOverrides: {
					root: { minHeight: 64 },
				},
			},
		},
	});

	const router = createBrowserRouter([
		{
			path: '/',
			element: <LandingPageV2 />,
		},
		{
			path: '/widget',
			element: <RecruitsWidget />,
		},
		{
			path: '/app',
			element: <Layout />,
			loader: checkAuthLoader,
			errorElement: <ErrorPage />,
			children: [
				{
					index: true,
					element: <Main />,
				},
				{
					path: 'talents',
					element: <Talents />,
				},
				{
					path: 'recruit',
					element: <RecruitListPage />,
				},
				{
					path: 'recruit/:recruitId',
					element: <RecruitPage />,
				},
				{
					path: 'talents/:talentId',
					element: <Talent />,
				},
				{
					path: 'companies',
					element: <CompaniesPage />,
				},
				{
					path: 'companies/:companyId',
					element: <CompanyPage />,
				},
				{
					path: 'academy',
					element: <AcademyPage />,
				},
				{
					path: 'manage',
					element: <ManagePage />,
				},
				{
					path: 'searchTalents',
					element: <TalentSearch />,
				},
			],
		},
		{
			path: '/login',
			element: <AuthForm />,
		},
		{
			path: '/register',
			element: <AuthForm isRegistering={true} />,
		},
	]);

	return (
		<QueryClientProvider client={queryClient}>
			<ThemeProvider theme={modernTheme}>
				<NotificationDialog />
				<RouterProvider router={router} />
			</ThemeProvider>
		</QueryClientProvider>
	);
}

export default App;
