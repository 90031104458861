import { useEffect, useState } from 'react';

export const useProfileFormVisibility = (isTalent, talent) => {
	const [isProfileFormOpen, setProfileFormOpen] = useState(false);
	const [hasOpenedOnce, setHasOpenedOnce] = useState(false); // Uusi tila

	useEffect(() => {
		const checkForMissingFields = () => {
			return (
				isTalent &&
				(!talent?.city ||
					!talent?.university ||
					!talent?.stage ||
					!talent?.major ||
					!talent?.phone ||
					// !talent?.motto ||
					!talent?.category ||
					(Array.isArray(talent?.category) &&
						talent.category.length === 0))
			);
		};

		if (checkForMissingFields() && !hasOpenedOnce) {
			setProfileFormOpen(true);
			setHasOpenedOnce(true); // Aseta tila true kun lomake avataan ensimmäisen kerran
		}
		// Tämä tyhjä dependency array varmistaa, että effect ajetaan vain ensimmäisellä renderöinnillä
	}, []);

	return [isProfileFormOpen, setProfileFormOpen];
};
