import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { visuallyHidden } from '@mui/utils';
import { styled } from '@mui/material/styles';
import HeroModal from './HeroModal';
import { useNavigate } from 'react-router-dom';
import { scrollToSection } from './AppAppBar';

const StyledBox = styled('div')(({ theme }) => ({
	alignSelf: 'center',
	width: '100%',
	height: 400,
	marginTop: theme.spacing(8),
	borderRadius: theme.shape.borderRadius,
	outline: '1px solid',
	boxShadow: '0 0 12px 8px hsla(220, 25%, 80%, 0.2)',
	backgroundImage: `url(${'https://lenz.fi/wp-content/uploads/2024/01/Rekrytointi-300x300-1.png'})`,
	outlineColor: 'hsla(220, 25%, 80%, 0.5)',
	backgroundSize: 'contain', // Pitää kuvan peittävänä
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center center', // Keskitetään kuva pystysuunnassa ja ylhäältä päin
	[theme.breakpoints.up('sm')]: {
		marginTop: theme.spacing(10),
		height: 650,
	},
	[theme.breakpoints.down('sm')]: {
		height: 300,
		backgroundSize: 'cover', // Sama idea myös pienemmillä näytöillä
		backgroundPosition: 'right center', // Pitää kuvan keskitettynä
	},
	...theme.applyStyles('dark', {
		boxShadow: '0 0 24px 12px hsla(210, 100%, 25%, 0.2)',
		backgroundImage: `url(${'https://lenz.fi/wp-content/uploads/2024/09/platform-kuvitus-scaled.jpg'})`,
		outlineColor: 'hsla(210, 100%, 80%, 0.1)',
		backgroundPosition: 'top center', // Sama asetus myös pimeässä teemassa
	}),
}));

export default function Hero({ userRole }) {
	const [modalOpen, setModalOpen] = React.useState(false);
	const [email, setEmail] = React.useState('');
	const navigate = useNavigate();

	const handleModalOpen = () => {
		setModalOpen(true);
	};

	const handleEmailChange = (event) => {
		setEmail(event.target.value);
	};

	return (
		<>
			<Box
				id="hero"
				sx={(theme) => ({
					width: '100%',
					backgroundRepeat: 'no-repeat',
					backgroundImage:
						'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
					...theme.applyStyles('dark', {
						backgroundImage:
							'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
					}),
				})}
			>
				<Container
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						pt: { xs: 14, sm: 20 },
						pb: { xs: 8, sm: 12 },
					}}
				>
					<Stack
						spacing={2}
						useFlexGap
						sx={{
							alignItems: 'center',
							width: { xs: '100%', sm: '75%' },
						}}
					>
						<Typography
							variant="h1"
							sx={{
								fontSize: {
									xs: '2.5rem',
									sm: '3rem',
									md: '4rem',
								}, // Responsiivinen fonttikoko
								textAlign: 'center', // Keskitetään teksti ilman Flexboxia
							}}
						>
							{userRole === 'company' ? 'Lenz' : 'Etsitkö'}
							&nbsp;
							<Typography
								component="span"
								variant="h1"
								sx={(theme) => ({
									fontSize: 'inherit', // Periytyy pääotsikon fonttikoko
									color: 'primary.main',
									...theme.applyStyles('dark', {
										color: 'primary.light',
									}),
								})}
							>
								{userRole === 'company'
									? 'Osaajapalvelut'
									: 'joustavaa työtä'}
							</Typography>
							&nbsp;
							{userRole === 'company' ? '' : 'opintojen ohelle?'}
						</Typography>

						{/* <Typography
              sx={{
                textAlign: 'center',
                color: 'text.secondary',
                width: { sm: '100%', md: '80%' },
              }}
            >
              Haluatko ulkoistaa tehtävät, joita yrityksesi ei osaa tai jaksa tehdä? Hyödynnä nuorten ammattilaisten uusimmat taidot, tiedot ja innostus. Me autamme sinua keskittymään siihen, missä olet parhaimmillasi.
            </Typography> */}
						{userRole === 'company' ? (
							<>
								<Typography
									sx={{
										textAlign: 'center',
										color: 'text.secondary',
										width: { sm: '100%', md: '80%' },
									}}
								>
									Yhdistämme huomisen huippuosaajat
									innovatiivisiin yrityksiin edistyneellä
									teknologialla. Löydä asiantuntija nopeasti
									ja helposti! Alkaen 40€/h.
								</Typography>
								<Stack
									direction={{ xs: 'column', sm: 'row' }}
									spacing={1}
									useFlexGap
									sx={{
										pt: 2,
										width: { xs: '100%', sm: 'auto' },
									}}
								>
									<InputLabel
										htmlFor="email-hero"
										sx={visuallyHidden}
									>
										Email
									</InputLabel>
									<TextField
										id="email-hero"
										hiddenLabel
										size="small"
										variant="outlined"
										aria-label="Enter your email address"
										placeholder="Sähköpostiosoitteesi"
										slotProps={{
											htmlInput: {
												autoComplete: 'off',
												'aria-label':
													'Enter your email address',
											},
										}}
										value={email}
										onChange={handleEmailChange}
									/>
									<Button
										variant="contained"
										color="primary"
										onClick={handleModalOpen}
									>
										Löydä Talentti
									</Button>
								</Stack>
								<Typography
									variant="caption"
									sx={{ textAlign: 'center' }}
								>
									Klikkaamalla &quot;Löydä Talentti&quot;
									hyväksyt &nbsp;
									<Link href="#" color="primary">
										Ehdot
									</Link>
									.
								</Typography>
							</>
						) : (
							<>
								<Typography
									sx={{
										textAlign: 'center',
										color: 'text.secondary',
										width: { sm: '100%', md: '80%' },
									}}
								>
									Hyödynnä taitosi ja tee merkityksellistä
									työtä joustavasti opintojesi ohella. Löydä
									inspiroivia projekteja, kartuta arvokasta
									kokemusta ja ansaitse rahaa opiskellessasi!
								</Typography>
								<Stack
									direction={{ xs: 'column', sm: 'row' }}
									spacing={1}
									useFlexGap
									sx={{
										pt: 2,
										width: { xs: '100%', sm: 'auto' },
									}}
								>
									<Button
										onClick={() =>
											scrollToSection('features')
										}
										variant="outlined"
									>
										Lue lisää
									</Button>
									<Button
										onClick={() => navigate('login')}
										variant="contained"
									>
										Kirjaudu sisään
									</Button>
								</Stack>
							</>
						)}
					</Stack>
					<StyledBox id="image" />
				</Container>
			</Box>
			<HeroModal
				open={modalOpen}
				handleClose={() => setModalOpen(false)}
				email={email}
			/>
		</>
	);
}
