import React, { useMemo } from 'react';
import {
	Radar,
	RadarChart,
	PolarGrid,
	PolarAngleAxis,
	PolarRadiusAxis,
	ResponsiveContainer,
} from 'recharts';
import { Paper, Typography } from '@mui/material';
import TeamRoles from 'components/TeamRoles';

const TeamRolesRadarChart = ({ teamRoles, favoriteColor }) => {
	const teamRoleTitles = {
		coordinator: 'Koordinaattori',
		challenger: 'Haastaja',
		visionary: 'Visionääri',
		analyst: 'Analyytikko',
		implementer: 'Toteuttaja',
		teamPlayer: 'Tiimipelaaja',
		researcher: 'Tutkija',
		finisher: 'Viimeistelijä',
		specialist: 'Asiantuntija',
	};

	const radarChartData = useMemo(
		() =>
			Object.keys(teamRoleTitles).map((key) => ({
				subject: teamRoleTitles[key],
				A: teamRoles[key],
				fullMark: 100,
			})),
		[teamRoles],
	);

	return (
		<>
			<Typography variant="h6" align="center" gutterBottom>
				Tiimirooli
			</Typography>
			<ResponsiveContainer width="100%" height={350}>
				<RadarChart
					cx="50%"
					cy="50%"
					outerRadius="60%"
					data={radarChartData}
				>
					<PolarGrid />
					<PolarAngleAxis
						dataKey="subject"
						tick={{ fontSize: '12px' }}
					/>
					<PolarRadiusAxis angle={30} />
					<Radar
						name="Tiimirooli"
						dataKey="A"
						stroke={favoriteColor}
						fill={favoriteColor}
						fillOpacity={0.6}
					/>
				</RadarChart>
			</ResponsiveContainer>
			<TeamRoles teamRoleData={teamRoles} />
		</>
	);
};

export default TeamRolesRadarChart;
