import React, { useState } from 'react';
import {
	Typography,
	Grid,
	Card,
	Paper,
	Chip,
	Box,
	Avatar,
	IconButton,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ApplicationPage from 'features/Application/components/ApplicationPage';
import Skills from './components/Skills';
import TalentLinks from './components/TalentLinks';
import CompleteProfileForm from 'features/Talent/components/CompleteProfileForm';
import ProfileInfo from './components/ProfileInfo';
import ProfileCompletion from './components/ProfileCompletion';
import Questionnaire from 'components/Questionnaire';
import questions from '../../questions/potentialtest/questions.json';
import TalentSkeleton from './components/TalentSkeleton';
import { useTalentData } from './hooks/useTalentData';
import { getRankLabel } from './utils/utils';
import TeamRolesRadarChart from './components/RadarChart';
import ApplicationCard from './components/ApplicationCard';
import { useProfileFormVisibility } from './hooks/useProfileFormVisibility';
import { selectIsTalent } from 'features/User/userSlice';
import { useTalentId } from './hooks/useTalentId';
import EditIcon from '@mui/icons-material/Edit';

const Talent = () => {
	const talentId = useTalentId();
	const isTalent = useSelector(selectIsTalent);
	const { talent, isLoading } = useTalentData(talentId);
	const [isProfileFormOpen, setProfileFormOpen] = useProfileFormVisibility(
		isTalent,
		talent,
	);
	const [selectedApplication, setSelectedApplication] = useState(null);
	const [isDialogOpen, setDialogOpen] = useState(false);
	const [isQuestionnaireOpen, setIsQuestionnaireOpen] = useState(false);

	const handleCloseDialog = () => {
		setDialogOpen(false);
		setSelectedApplication(null);
	};

	const handleEditClick = () => {
		setProfileFormOpen(true);
	};

	const checkForMissingFields = () => {
		return (
			isTalent &&
			(!talent?.city ||
				!talent?.university ||
				!talent?.stage ||
				!talent?.major ||
				!talent?.phone ||
				!talent?.category ||
				(Array.isArray(talent?.category) &&
					talent.category.length === 0))
		);
	};

	if (isLoading) return <TalentSkeleton />;

	return (
		<>
			<Grid container spacing={4}>
				<Grid item xs={12} md={6}>
					<Paper
						elevation={3}
						sx={{
							padding: 3,
							textAlign: 'center',
							backgroundColor:
								talent?.favoriteColor || 'primary.main',
							color: 'white',
							mb: 2,
							position: 'relative', // Make sure the icon is positioned relatively to the Paper
						}}
					>
						<IconButton
							onClick={handleEditClick}
							sx={{
								position: 'absolute',
								top: 8,
								right: 8,
								color: 'white',
								animation: checkForMissingFields()
									? 'blinker 1.5s linear infinite'
									: 'none',
								'@keyframes blinker': {
									'50%': { opacity: 0 },
								},
							}}
							title="Muokkaa profiilia"
						>
							<EditIcon />
						</IconButton>
						<Avatar
							alt={talent?.user?.name?.split(' ')[0]}
							src={talent?.user?.avatarUrl}
							sx={{
								width: 150,
								height: 150,
								margin: '0 auto 16px',
							}}
						/>
						<Typography variant="h4" sx={{ fontWeight: 'bold' }}>
							{talent?.user?.name || talent?.name}
						</Typography>
						<Chip
							label={getRankLabel(talent?.talentPoolRank)}
							sx={{
								backgroundColor: 'rgba(255, 255, 255, 0.3)',
								color: '#fff',
								height: 32,
								fontSize: '1rem',
								fontWeight: 'bold',
								marginTop: 2,
							}}
						/>
						{talent?.motto && (
							<Typography
								variant="body1"
								sx={{ fontStyle: 'italic', marginTop: 2 }}
							>
								{`"${talent.motto}"`}
							</Typography>
						)}
						{talent?.category?.length > 0 && (
							<Box
								sx={{
									marginTop: 3,
									display: 'flex',
									justifyContent: 'center',
									gap: 1,
									flexWrap: 'wrap',
								}}
							>
								{talent.category.map((category, index) => (
									<Chip
										key={index}
										label={category}
										sx={{
											backgroundColor:
												'rgba(255, 255, 255, 0.2)',
											color: '#fff',
											height: 28,
											fontSize: '0.875rem',
											fontWeight: 'bold',
										}}
									/>
								))}
							</Box>
						)}
					</Paper>
					<ProfileCompletion
						isTalent={isTalent}
						talent={talent}
						color={talent.favoriteColor || 'primary.main'}
						open={() => setIsQuestionnaireOpen(true)}
						talentId={talentId}
					/>

					<ProfileInfo
						talentId={talentId}
						talent={talent}
						isTalent={isTalent}
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<TeamRolesRadarChart
						teamRoles={talent?.teamrole}
						favoriteColor={talent?.favoriteColor}
					/>
					<Skills
						skills={talent?.skills}
						talentId={talentId}
						color={talent?.favoriteColor}
					/>

					<Paper
						elevation={3}
						sx={{ padding: 3, marginTop: 4, marginBottom: 4 }}
					>
						<Typography
							variant="h5"
							gutterBottom
							sx={{ fontWeight: 'bold' }}
						>
							Hakemukset
						</Typography>
						{!(talent?.applications.length === 0) ? (
							talent?.applications.map((application, index) => (
								<ApplicationCard
									key={index}
									application={application}
								/>
							))
						) : (
							<Card sx={{ my: 1, p: 2 }}>
								<Typography>
									Et ole vielä hakenut Lenzin projekteihin.
									<Box
										component={Link}
										to="/app/recruit"
										sx={{
											textDecoration: 'none',
											color: 'primary.main',
											fontWeight: 'bold',
											mx: 1,
											'&:hover': {
												textDecoration: 'underline',
												color: 'primary.dark',
											},
										}}
									>
										Tutustu rekrytointimahdollisuuksiin
									</Box>
									ja lähetä hakemuksesi.
								</Typography>
							</Card>
						)}
					</Paper>
					<TalentLinks talentId={talentId} links={talent?.links} />
				</Grid>

				<Questionnaire
					talentId={talentId}
					sections={questions.sections}
					open={isQuestionnaireOpen}
					onClose={() => setIsQuestionnaireOpen(false)}
				/>

				<ApplicationPage
					application={selectedApplication}
					open={isDialogOpen}
					onClose={handleCloseDialog}
				/>
			</Grid>

			<CompleteProfileForm
				open={isProfileFormOpen}
				onClose={() => setProfileFormOpen(false)}
				initialData={talent}
			/>
		</>
	);
};

export default Talent;
