import React from 'react';
import { Card, Typography } from '@mui/material';

const ApplicationCard = ({ application, onClick }) => (
	<Card sx={{ my: 1, p: 2 }} onClick={onClick}>
		<Typography variant="h6">Rekry: {application.title}</Typography>
		<Typography variant="subtitle1">
			{' '}
			Hakemus jätetty: {application.submitted_at}
		</Typography>
		<Typography variant="subtitle2">
			{' '}
			Pisteet: {application.score}
		</Typography>
		<Typography variant="subtitle2">
			{' '}
			Vaihe: {application.currentStage}
		</Typography>
	</Card>
);

export default ApplicationCard;
