import React, { useRef, useState } from 'react';
import {
	Modal,
	Box,
	Typography,
	TextField,
	Button,
	Grid,
	Paper,
	Stepper,
	Step,
	StepLabel,
	MenuItem,
	Select,
	FormControl,
	InputLabel,
} from '@mui/material';
import { styled } from '@mui/system';
import emailjs from 'emailjs-com';

const StyledModal = styled(Modal)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));

const ModalContent = styled(Paper)(({ theme }) => ({
	padding: theme.spacing(4),
	maxWidth: '600px',
	width: '100%',
	borderRadius: '16px',
	position: 'relative',
	[theme.breakpoints.down('sm')]: {
		padding: theme.spacing(2),
	},
	boxShadow:
		'0 1px 2px hsla(210, 0%, 0%, 0.05), 0 2px 12px hsla(210, 100%, 80%, 0.5), 0 0 30px 10px hsla(210, 100%, 80%, 0.4)',
	backgroundColor: theme.palette.background.paper,
	...theme.applyStyles('dark', {
		boxShadow:
			'0 1px 2px hsla(210, 0%, 0%, 0.5), 0 2px 12px hsla(210, 100%, 25%, 0.3), 0 0 30px 10px hsla(210, 100%, 25%, 0.4)',
	}),
}));

const steps = [
	{ label: 'Yhteystiedot' },
	{ label: 'Talenttien määrä' },
	{ label: 'Tarpeen kuvaus' },
];

const stepInstructions = [
	'Täytä yrityksesi tiedot ja puhelinnumerosi, jotta voimme ottaa sinuun yhteyttä.',
	'Ilmoita kuinka monta talenttia tarvitset projektiisi.',
	'Kuvaile tarkemmin tarpeesi, ja otamme sinuun yhteyttä 24 tunnin sisällä ehdotuksinemme.',
];

const TalentRequestModal = ({ open, handleClose, email }) => {
	const form = useRef();
	const [formData, setFormData] = useState({
		companyName: '',
		email,
		phone: '',
		description: '',
		henkilomaara: '',
		taskType: '',
		additionalInfo: '',
	});

	const [activeStep, setActiveStep] = useState(0);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		emailjs
			.sendForm(
				'service_gr4z0wu',
				'template_dizce68',
				form.current,
				'nSdbO8FCLt9IVuacl',
			)
			.then(
				() => {
					console.log('SUCCESS!');
					alert('Lomake lähetetty onnistuneesti!');
				},
				(error) => {
					console.log('FAILED...', error.text);
					alert('Lomakkeen lähetys epäonnistui.');
				},
			);

		setFormData({
			companyName: '',
			email: '',
			phone: '',
			description: '',
			henkilomaara: '',
			taskType: '',
			additionalInfo: '',
		});
		setActiveStep(0);
		handleClose();
	};

	const handleStep = (step) => {
		setActiveStep(step);
	};

	return (
		<StyledModal open={open} onClose={handleClose}>
			<ModalContent>
				<Typography variant="h4" gutterBottom align="center">
					Löydä asiantuntija nopeasti!
				</Typography>
				<Typography variant="body1" gutterBottom align="center">
					{stepInstructions[activeStep]}
				</Typography>
				<Box sx={{ mb: 3 }}>
					<Stepper activeStep={activeStep} alternativeLabel>
						{steps.map((step, index) => (
							<Step key={step.label}>
								<StepLabel
									onClick={() => handleStep(index)}
									sx={{
										cursor: 'pointer',
										'& .MuiStepIcon-text': { fill: '#000' }, // Change stepper number color to black
									}}
								>
									{step.label}
								</StepLabel>
							</Step>
						))}
					</Stepper>
				</Box>
				<form ref={form} onSubmit={handleSubmit}>
					<Grid container spacing={3}>
						{activeStep === 0 && (
							<>
								<Grid item xs={12}>
									<TextField
										label="Yrityksen nimi"
										name="companyName"
										value={formData.companyName}
										onChange={handleChange}
										fullWidth
										required
										variant="outlined"
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										label="Puhelinnumero"
										name="phone"
										value={formData.phone}
										onChange={handleChange}
										fullWidth
										required
										type="tel"
										variant="outlined"
									/>
								</Grid>
								<Grid item xs={12}>
									<Button
										fullWidth
										variant="contained"
										color="primary"
										onClick={() => handleStep(1)}
									>
										Seuraava
									</Button>
								</Grid>
							</>
						)}
						{activeStep === 1 && (
							<>
								<Grid item xs={12}>
									<TextField
										label="Talenttien määrä"
										name="henkilomaara"
										value={formData.henkilomaara}
										onChange={handleChange}
										fullWidth
										required
										type="number"
										variant="outlined"
									/>
								</Grid>
								<Grid item xs={12}>
									<Button
										fullWidth
										variant="contained"
										color="primary"
										onClick={() => handleStep(2)}
									>
										Seuraava
									</Button>
									<Button
										fullWidth
										variant="outlined"
										color="primary"
										onClick={() => handleStep(0)}
										sx={{ mt: 1 }}
									>
										Takaisin
									</Button>
								</Grid>
							</>
						)}
						{activeStep === 2 && (
							<>
								<Grid item xs={12}>
									<Typography variant="body2" gutterBottom>
										Kuvaile tarpeesi mahdollisimman
										tarkasti, jotta voimme löytää parhaan
										mahdollisen asiantuntijan.
									</Typography>
									<TextField
										label="Kuvaus tarpeesta"
										name="description"
										value={formData.description}
										onChange={handleChange}
										fullWidth
										required
										multiline
										rows={4}
										variant="outlined"
										sx={{
											'& .MuiInputBase-root': {
												alignItems: 'flex-start',
											},
										}}
									/>
								</Grid>
								{/* <Grid item xs={12}>
                <Typography variant="body2" gutterBottom>
                    Anna arvioitu kesto tehtävälle tunneissa.
                </Typography>
                <TextField
                    label="Arvioitu kesto (tuntia)"
                    name="duration"
                    value={formData.duration}
                    onChange={handleChange}
                    fullWidth
                    required
                    type="number"
                    variant="outlined"
                />
                </Grid> */}
								<Grid item xs={12}>
									<Typography variant="body2" gutterBottom>
										Valitse tehtävän tyyppi: Resurssivuokra
										tai QuickTask.
									</Typography>
									<FormControl
										fullWidth
										required
										variant="outlined"
									>
										<InputLabel id="taskType-label">
											Tehtävän tyyppi
										</InputLabel>
										<Select
											labelId="taskType-label"
											name="taskType"
											value={formData.taskType}
											onChange={handleChange}
											label="Tehtävän tyyppi"
										>
											<MenuItem value="Resurssivuokra">
												Resurssivuokra (Pitkäaikainen
												yhteistyö, jatkuva tarve)
											</MenuItem>
											<MenuItem value="QuickTask">
												QuickTask (Yksittäinen, tarkasti
												määritelty tehtävä)
											</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12}>
									<Typography variant="body2" gutterBottom>
										Kirjoita tähän muita tarpeita tai
										huomioita, jotka haluat meidän tietävän.
									</Typography>
									<TextField
										label="Muut tarpeet"
										name="additionalInfo"
										value={formData.additionalInfo}
										onChange={handleChange}
										fullWidth
										multiline
										rows={3}
										variant="outlined"
										sx={{
											'& .MuiInputBase-root': {
												alignItems: 'flex-start',
											},
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									<Button
										type="submit"
										variant="contained"
										fullWidth
									>
										Lähetä pyyntö
									</Button>
									<Button
										fullWidth
										variant="outlined"
										color="primary"
										onClick={() => handleStep(1)}
										sx={{ mt: 1 }}
									>
										Takaisin
									</Button>
								</Grid>
							</>
						)}
					</Grid>
				</form>
			</ModalContent>
		</StyledModal>
	);
};

export default TalentRequestModal;
