// utils.js
export const getRankColor = (rank) => {
	switch (rank) {
		case 1:
			return '#ffb300';
		case 2:
			return '#2196f3';
		case 3:
			return '#4caf50';
		default:
			return '#e0e0e0';
	}
};

export const getRankLabel = (rank) => {
	switch (rank) {
		case 1:
			return 'Seniori';
		case 2:
			return 'Asiantuntija';
		case 3:
			return 'Opiskelija';
		default:
			return 'Tuntematon';
	}
};
