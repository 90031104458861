import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';
import AppAppBar from './components/AppAppBar';
import Hero from './components/Hero';
import LogoCollection from './components/LogoCollection';
import Highlights from './components/Highlights';
import Pricing from './components/Pricing';
import Features from './components/Features';
import Testimonials from './components/Testimonials';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import getLPTheme from './getLPTheme';
import Contact from './components/Contact';
import RecruitsWidget from 'features/Recruits/components/RecruitsWidget';
import ProcessDescription from './components/ProcessDescription';

export default function LandingPageV2() {
	const [mode, setMode] = useState('dark');
	const [showCustomTheme, setShowCustomTheme] = useState(true);
	const [userRole, setUserRole] = useState(null);

	const LPtheme = createTheme(getLPTheme(mode));
	const defaultTheme = createTheme({ palette: { mode } });

	useEffect(() => {
		const sessionRole = sessionStorage.getItem('userRole');
		if (sessionRole) {
			setUserRole(sessionRole);
		} else {
			setUserRole('student');
			sessionStorage.setItem('userRole', 'student');
		}
	}, []);

	const handleSetUserRole = (role) => {
		setUserRole(role);
		sessionStorage.setItem('userRole', role);
	};

	const toggleColorMode = () => {
		setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
	};

	return (
		<ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
			<CssBaseline />
			<AppAppBar
				mode={mode}
				toggleColorMode={toggleColorMode}
				userRole={userRole}
				setUserRole={handleSetUserRole}
			/>
			<Box sx={{ bgcolor: 'background.default' }}>
				<Hero userRole={userRole} />
				<LogoCollection />
				<Features userRole={userRole} />
				<Divider />
				<Testimonials userRole={userRole} />
				{userRole === 'student' && (
					<>
						<Divider />
						<RecruitsWidget />
					</>
				)}
				{userRole === 'company' && (
					<>
						<Divider />
						<Pricing />
					</>
				)}

				<Divider />
				{/* <ProcessDescription />
				<Divider /> */}
				<Contact userRole={userRole} />
			</Box>
		</ThemeProvider>
	);
}
