import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';

const companyTeamMembers = [
	{
		name: 'Santtu Laivuori',
		role: 'CEO',
		email: 'santtu.laivuori@lenz.fi',
		phone: '+358 40 234 5678',
		image: 'https://media.licdn.com/dms/image/D4D03AQHdPA-cKx0B5w/profile-displayphoto-shrink_200_200/0/1678993471597?e=1727308800&v=beta&t=j3kFc7g1Rtkzwsd_O3JrFYDyllAcEUgGG1emiePAArA',
	},
	{
		name: 'Janne Taisto',
		role: 'CSO',
		email: 'janne.taisto@lenz.fi',
		phone: '+358 40 345 6789',
		image: 'https://lenz.fi/wp-content/uploads/2022/12/janne-scaled-1-400x397.jpg',
	},
	{
		name: 'Tuukka Hartikainen',
		role: 'CTO',
		email: 'tuukka.hartikainen@lenz.fi',
		phone: '+358 45 656 7226',
		image: 'https://media.licdn.com/dms/image/D4D03AQFdONjIK2t-4w/profile-displayphoto-shrink_200_200/0/1691906305050?e=1727308800&v=beta&t=0ZqcPwCFm18o1gKYbTNDyzYW6viUPuXWwqkfH2852ds',
	},
];

const studentTeamMembers = [
	{
		name: 'Amanda Pösö',
		role: 'Rekrytointivastaava',
		email: 'amanda.poso@lenz.fi',
		phone: '',
		image: 'https://lenz.fi/wp-content/uploads/2024/03/Artboard-9-400x400.jpg',
	},
	{
		name: 'Anissa Mouhou',
		role: 'Rerkrytoinnin asiantuntija',
		email: 'anissa.mouhou@lenz.fi',
		phone: '',
		image: 'https://lenz.fi/wp-content/uploads/2024/02/CV1-400x356.png',
	},
	{
		name: 'Eeva Teronen',
		role: 'Ihmiset & Kulttuuri-vastaava',
		email: 'eeva.teronen@lenz.fi',
		phone: '',
		image: 'https://lenz.fi/wp-content/uploads/2023/02/eeva-400x400.jpg',
	},
];

export default function Contact({ userRole }) {
	const teamMembers =
		userRole === 'student' ? studentTeamMembers : companyTeamMembers;

	return (
		<Container
			id="contact"
			sx={{
				pt: { xs: 4, sm: 12 },
				pb: { xs: 8, sm: 16 },
				position: 'relative',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				gap: { xs: 3, sm: 6 },
			}}
		>
			<Box
				sx={{
					width: { sm: '100%', md: '60%' },
					textAlign: { sm: 'left', md: 'center' },
				}}
			>
				<Typography
					component="h2"
					variant="h4"
					sx={{ color: 'text.primary' }}
				>
					Yhteystiedot
				</Typography>
				<Typography variant="body1" sx={{ color: 'text.secondary' }}>
					Ota yhteyttä tiimiimme. Olemme täällä auttamassa sinua
					kaikissa kysymyksissä ja tarpeissa.
				</Typography>
			</Box>
			<Grid
				container
				spacing={3}
				sx={{ alignItems: 'center', justifyContent: 'center' }}
			>
				{teamMembers.map((member) => (
					<Grid item key={member.name} xs={12} sm={6} md={4}>
						<Card
							sx={{
								p: 2,
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								gap: 2,
							}}
						>
							<Avatar
								alt={member.name}
								src={member.image}
								sx={{ width: 150, height: 150 }}
							/>
							<CardContent sx={{ textAlign: 'center' }}>
								<Typography
									component="h3"
									variant="h6"
									sx={{ color: 'text.primary' }}
								>
									{member.name}
								</Typography>
								<Typography
									variant="body2"
									sx={{ color: 'text.secondary' }}
								>
									{member.role}
								</Typography>
								<Typography
									variant="body2"
									sx={{ color: 'text.secondary' }}
								>
									{member.email}
								</Typography>
								<Typography
									variant="body2"
									sx={{ color: 'text.secondary' }}
								>
									{member.phone}
								</Typography>
							</CardContent>
						</Card>
					</Grid>
				))}
			</Grid>
		</Container>
	);
}
