import React, { useState, useEffect } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import {
	Card,
	CardContent,
	Typography,
	Divider,
	TextField,
	CardActions,
	Button,
} from '@mui/material';
import { talentService } from 'services';

const ProfileInfo = ({ talentId, talent, isTalent, color }) => {
	const queryClient = useQueryClient();
	const [editMode, setEditMode] = useState(false);
	const [updatedTalent, setUpdatedTalent] = useState(talent);

	useEffect(() => {
		setUpdatedTalent(talent);
	}, [talent]);

	const updateMutation = useMutation(
		(updatedData) => talentService.updateTalentById(talentId, updatedData),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['talentData', talentId]);
			},
		},
	);

	const handleInputChange = (event) => {
		setUpdatedTalent({
			...updatedTalent,
			[event.target.name]: event.target.value,
		});
	};

	const handleEditClick = () => {
		setEditMode(true);
	};

	const handleSaveClick = () => {
		const updatedData = {
			description: updatedTalent.description,
		};
		updateMutation.mutate(updatedData);
		setEditMode(false);
	};

	const handleCancelClick = () => {
		setUpdatedTalent(talent);
		setEditMode(false);
	};

	return (
		<Card sx={{ overflow: 'hidden' }}>
			<CardContent onClick={!editMode ? handleEditClick : undefined}>
				<Typography variant="subtitle1" color="text.secondary">
					<strong>Pääaine:</strong> {talent?.major}
				</Typography>
				{talent?.minor && (
					<Typography variant="subtitle1" color="text.secondary">
						<strong>Sivuaine:</strong> {talent?.minor}
					</Typography>
				)}
				<Typography variant="subtitle1" color="text.secondary">
					<strong>Oppilaitos:</strong> {talent?.university}
				</Typography>
				<Typography variant="subtitle1" color="text.secondary">
					<strong>Kaupunki:</strong> {talent?.city}
				</Typography>
				<Typography variant="subtitle1" color="text.secondary">
					<strong>Sähköposti:</strong> {talent?.user?.email}
				</Typography>
				<Typography variant="subtitle1" color="text.secondary">
					<strong>Puhelin:</strong> {talent?.phone}
				</Typography>
				<Divider variant="middle" sx={{ my: 3 }} />
				{isTalent && !talent?.description ? (
					<>
						<Typography variant="h6" color={color}>
							Kerro meille lisää itsestäsi – sana on vapaa! Voit
							kertoa esimerkiksi mielenkiinnonkohteistasi,
							osaamisestasi ja unelmistasi. Tämä on tilaisuutesi
							erottua ja kertoa, kuka juuri sinä olet! 💫
						</Typography>
						<Typography
							variant="subtitle2"
							color="text.secondary"
							sx={{ mt: 1 }}
						>
							Näiden tietojen avulla voimme tarjota sinulle
							parhaiten sopivia projekteja. Voit päivittää
							kuvausta milloin tahansa.
						</Typography>
					</>
				) : (
					<Typography variant="h6" color="text.secondary">
						Esittely
					</Typography>
				)}
				<Typography
					variant="body1"
					color="text.secondary"
					style={{ whiteSpace: 'pre-wrap' }}
				>
					{editMode ? (
						<TextField
							type="text"
							fullWidth
							variant="standard"
							multiline
							rows={4}
							name="description"
							value={updatedTalent?.description}
							onChange={handleInputChange}
							sx={{ mt: 2 }}
						/>
					) : (
						talent?.description
					)}
				</Typography>
			</CardContent>
			<CardActions sx={{ justifyContent: 'flex-end', p: 2 }}>
				{!editMode && isTalent && (
					<Button
						variant="outlined"
						color="primary"
						onClick={handleEditClick}
					>
						Muokkaa kuvausta
					</Button>
				)}
				{editMode && (
					<>
						<Button
							variant="contained"
							color="primary"
							onClick={handleSaveClick}
						>
							Tallenna
						</Button>
						<Button
							variant="outlined"
							color="primary"
							onClick={handleCancelClick}
						>
							Peruuta
						</Button>
					</>
				)}
			</CardActions>
		</Card>
	);
};

export default ProfileInfo;
