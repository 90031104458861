import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';

const logos = [
	'https://lenz.fi/wp-content/uploads/2022/01/qt_logo_with_text_white_rgb-400x139.png',
	'https://lenz.fi/wp-content/uploads/2023/10/Artboard-1@72x-400x97.png',
	'https://www.wihuri.fi/wp-content/uploads/2023/04/Wihuri-4v-vaaka.png',
	'https://lenz.fi/wp-content/uploads/2021/10/talenom-logo.png',
	'https://lenz.fi/wp-content/uploads/2023/02/um4Jn87x_1619442734_Digital_Visma_logo_white-1-400x92.png',
];

const logoStyle = {
	maxWidth: '120px',
	margin: '0 32px',
	opacity: 0.7,
};

export default function LogoCollection() {
	const theme = useTheme();

	return (
		<Box id="logoCollection" sx={{ py: 4 }}>
			<Typography
				component="p"
				variant="subtitle2"
				align="center"
				sx={{ color: 'text.secondary', mb: 2 }}
			>
				Yhteistyökumppaneitamme
			</Typography>
			<Grid
				container
				sx={{ justifyContent: 'center', mt: 0.5, opacity: 0.6 }}
			>
				{logos.map((logo, index) => (
					<Grid item key={index}>
						<img
							src={logo}
							alt={`Fake company number ${index + 1}`}
							style={logoStyle}
						/>
					</Grid>
				))}
			</Grid>
		</Box>
	);
}
