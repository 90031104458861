import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

// const tiers = [
//   {
//     title: 'Free',
//     price: '0',
//     description: [
//       'Talenttien rajaton etsiminen',
//       '50% komissio toteutuneista tunneista',
//       '6kk koeaika',
//       '2000€ rekrytointipalkkio'
//     ],
//     buttonText: 'Sign up for free',
//     buttonVariant: 'outlined',
//   },
//   {
//     title: 'Professional',
//     subheader: 'Recommended',
//     price: '100',
//     description: [
//       'Talenttien rajaton etsiminen',
//       '40% komissio toteutuneista tunneista',
//       '3kk koeaika',
//       '1000 € rekrytointipalkkio',
//     ],
//     buttonText: 'Start now',
//     buttonVariant: 'contained',
//   },
//   {
//     title: 'Premium',
//     price: '200',
//     description: [
//       'Talenttien hakeminen ',
//       'Tiimien muodostaminen',
//       '30% rekrytointipalkkio',
//       'Ei koeaikaa tai rekrytointipalkkiota',
//     ],
//     buttonText: 'Contact us',
//     buttonVariant: 'outlined',
//   },
// ];

const tiers = [
	{
		title: 'Basic',
		price: '0',
		description: [
			'Rajaton talenttien etsintä',
			'50% komissio toteutuneista tunneista',
			'10% komissio QuickTasks-tehtävistä',
			'Edistynyt algoritmi ehdottaa asiantuntijoita',
		],
		buttonText: 'Aloita ilmaiseksi',
		buttonVariant: 'outlined',
	},
	{
		title: 'Professional',
		subheader: 'Suositeltu',
		price: '100',
		description: [
			'Rajaton talenttien etsintä',
			'40% komissio toteutuneista tunneista',
			'Ei komissiota QuickTasks-tehtävistä',
			'Tehtävien ja rekryjen hallinta',
			'Parannettu näkyvyys ja seuranta',
		],
		buttonText: 'Aloita nyt',
		buttonVariant: 'contained',
	},
	{
		title: 'Innovaatioprojektit',
		price: '',
		description: [
			'Räätälöity tiimi ja projekti spesifiin tarpeeseen',
			'Kertaluontainen maksu',
			'Kokenut projektipäällikkö',
			'Täydellinen läpinäkyvyys projektinhallintaan ja seurantaan',
		],
		buttonText: 'Ota yhteyttä',
		buttonVariant: 'outlined',
	},
];

export default function Pricing() {
	return (
		<Container
			id="pricing"
			sx={{
				pt: { xs: 4, sm: 12 },
				pb: { xs: 8, sm: 16 },
				position: 'relative',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				gap: { xs: 3, sm: 6 },
			}}
		>
			<Box
				sx={{
					width: { sm: '100%', md: '60%' },
					textAlign: { sm: 'left', md: 'center' },
				}}
			>
				<Typography
					component="h2"
					variant="h4"
					sx={{ color: 'text.primary' }}
				>
					Hinnoittelu
				</Typography>
				<Typography variant="body1" sx={{ color: 'text.secondary' }}>
					Alustamme on ilmainen lukuunottamatta konsultoinnin
					tavanomaista välityspalkkiota. <br />
					Halutessasi voit kuitenkin säästää tilaamalla palvelun.
				</Typography>
			</Box>
			<Grid
				container
				spacing={3}
				sx={{ alignItems: 'center', justifyContent: 'center' }}
			>
				{tiers.map((tier) => (
					<Grid
						item
						key={tier.title}
						xs={12}
						sm={tier.title === 'Enterprise' ? 12 : 6}
						md={4}
					>
						<Card
							sx={[
								{
									p: 2,
									display: 'flex',
									flexDirection: 'column',
									gap: 4,
								},
								tier.title === 'Professional' &&
									((theme) => ({
										border: 'none',
										background:
											'radial-gradient(circle at 50% 0%, hsl(220, 97%, 70%), hsl(210, 100%, 16%))',
										boxShadow: `0 8px 12px hsla(210, 98%, 42%, 0.2)`,
										...theme.applyStyles('dark', {
											boxShadow: `0 8px 12px hsla(0, 0%, 0%, 0.8)`,
										}),
									})),
							]}
						>
							<CardContent>
								<Box
									sx={[
										{
											mb: 1,
											display: 'flex',
											justifyContent: 'space-between',
											alignItems: 'center',
											gap: 2,
										},
										tier.title === 'Professional'
											? { color: 'grey.100' }
											: { color: '' },
									]}
								>
									<Typography component="h3" variant="h6">
										{tier.title}
									</Typography>
									{tier.title === 'Professional' && (
										<Chip
											icon={<AutoAwesomeIcon />}
											label={tier.subheader}
											size="small"
											sx={{
												borderColor:
													'hsla(220, 60%, 99%, 0.3)',
												backgroundColor:
													'hsla(220, 60%, 99%, 0.1)',
												'& .MuiChip-label': {
													color: 'hsl(0, 0%, 100%)',
												},
												'& .MuiChip-icon': {
													color: 'primary.light',
												},
											}}
										/>
									)}
								</Box>
								<Box
									sx={[
										{
											display: 'flex',
											alignItems: 'baseline',
										},
										tier.title === 'Professional'
											? { color: 'grey.50' }
											: { color: null },
									]}
								>
									{tier.price === '' ? (
										<Typography component="h3" variant="h4">
											Kysy hintaa
										</Typography>
									) : (
										<>
											<Typography
												component="h3"
												variant="h2"
											>
												{tier.price}€
											</Typography>
											<Typography
												component="h3"
												variant="h6"
											>
												&nbsp; kuukaudessa
											</Typography>
										</>
									)}
								</Box>
								<Divider
									sx={{
										my: 2,
										opacity: 0.8,
										borderColor: 'divider',
									}}
								/>
								{tier.description.map((line) => (
									<Box
										key={line}
										sx={{
											py: 1,
											display: 'flex',
											gap: 1.5,
											alignItems: 'center',
										}}
									>
										<CheckCircleRoundedIcon
											sx={[
												{
													width: 20,
												},
												tier.title === 'Professional'
													? { color: 'primary.light' }
													: { color: 'primary.main' },
											]}
										/>
										<Typography
											variant="subtitle2"
											component={'span'}
											sx={[
												tier.title === 'Professional'
													? { color: 'grey.50' }
													: { color: null },
											]}
										>
											{line}
										</Typography>
									</Box>
								))}
							</CardContent>
							<CardActions>
								<Button fullWidth variant={tier.buttonVariant}>
									{tier.buttonText}
								</Button>
							</CardActions>
						</Card>
					</Grid>
				))}
			</Grid>
		</Container>
	);
}
